<template>
  <div id="pageBlog" class="mainContents">
    <section class="titleArea">
      <h2>運営ブログ</h2>
      <!-- /.titleArea -->
    </section>

    <BlogCard
      v-for="blog in blog.blogs"
      :key="blog.node.id"
      :blog="blog.node"
    />

    <div class="buttonArea">
      <p>
        <button
          v-if="blog.hasNextPage"
          type="submit"
          class="button"
          @click="getMoreBlogs($event)"
        >
          もっと見る
        </button>
      </p>
      <!-- /.buttonArea -->
    </div>

    <!-- /.mainContents -->
  </div>
</template>

<script>
import BlogCard from '@/components/BlogCard.vue'
import { mapState } from 'vuex'
import store from '@/store/index'

function getPageBlogs (routeTo, next) {
  const args = { first: 3 }
  store
    .dispatch('blog/fetchBlogs', args)
    .then(() => {
      next()
    })
    .catch(() => {
      next({ name: 'network-issue' })
    })
}

export default {
  components: {
    BlogCard
  },
  data () {
    return {
      blogs: []
    }
  },
  beforeRouteEnter (routeTo, routeFrom, next) {
    getPageBlogs(routeTo, next)
  },
  beforeRouteUpdate (routeTo, routeFrom, next) {
    getPageBlogs(routeTo, next)
  },
  computed: {
    ...mapState(['blog'])
  },
  mounted () {},
  methods: {
    getMoreBlogs (event) {
      event.preventDefault()
      const args = {
        first: 3,
        after: this.blog.endCursor,
        add: true
      }
      store.dispatch('blog/fetchBlogs', args)
    },
    getBlogs: function () {
      const args = { first: 3 }
      store
        .dispatch('blog/fetchBlogs', args)
        .then(() => {})
        .catch(() => {})
    }
  }
}
</script>

<style scoped>
div#blog {
  width: 800px;
  margin: auto;
}
</style>
