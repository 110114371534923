<template>
  <div class="blogArea">
    <div class="inner">
      <h2>{{ blog.title }}</h2>
      <div class="editorInner" v-html="blog.description" />
      <template v-if="blog.image">
        <div class="editorInner">
          <div class="medium-insert-images">
            <img :src="blog.image" alt="">
          </div>
        </div>
      </template>
      <p class="update">
        {{ formatDate(blog.display_date) }}
      </p>
      <div class="profile">
        <a
          href="#"
        ><p>
          <img
            :src="blog.profile_image + '?d=50x50'"
            :alt="blog.name"
            class="obj-fit-img"
          ><span>{{ blog.name }}</span>
        </p></a>
      </div>
    </div>
    <!-- /.blogArea -->
  </div>
</template>

<script>
import { commonDate } from '@/helpers/date-format.js'

export default {
  name: 'BlogCard',
  props: {
    blog: {
      type: Object,
      default: null
    }
  },
  methods: {
    formatDate (date) {
      return commonDate(date, 'YYYY.MM.DD')
    }
  }
}
</script>

<style scoped></style>
